import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { staticLinks } from "../../config/routingsLinks";
import styles from './PlanetPage.module.css'
import { MatrixRain } from "../../components/MatrixRain/MatrixRain";

const PlanetPage = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <textarea className={styles.text}>Выпущено тап 20.</textarea>
        </div>
        <div>
          <textarea className={styles.text}>Стоимость тапы 20 рублей.</textarea>
        </div>
        <div>
          <textarea className={styles.text}>Бюджет Акционерного общества 400 рублей.</textarea>
        </div>
        <MatrixRain></MatrixRain>
      </div>
      
    </>
  );
};

export default PlanetPage;