import { staticLinks } from "../config/routingsLinks";
import MainPage from "../pages/MainPage/MainPage";
import MailPage from "../pages/MailPage/MailPage";
import PlanetPage from "../pages/PlanetPage/PlanetPage";


export const publicRoutes = [
  {
    path: staticLinks.main,
    element: <MainPage></MainPage>,
  },
  {
    path: staticLinks.mail,
    element: <MailPage></MailPage>,
  },
  {
    path: staticLinks.planet,
    element: <PlanetPage></PlanetPage>,
  },
];
